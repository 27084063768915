var render = function () {
  var _vm$header$data, _vm$header$data2, _vm$header$data3, _vm$header$data4, _vm$header$data5, _vm$header$data6, _vm$header$data7, _vm$header$data8, _vm$layananUtama, _vm$layananUtama$data, _vm$layananUtama2, _vm$layananUtama2$dat, _vm$layananUtama3, _vm$layananUtama3$dat, _vm$layananUtama4, _vm$layananUtama4$dat, _vm$layananUtama6, _vm$layananUtama6$dat, _vm$layananUtama6$dat2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "background-color": ['#F2F2F2', '#FFF'],
      "padding-bottom": "16px",
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "position": "relative",
      "mx": "auto",
      "background-color": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "min-height": ['calc(100vh - 62px)', '74vh'],
      "width": "100%",
      "max-width": "1270px"
    }
  }, [_c('c-box', {
    attrs: {
      "padding": ['0 0 0 0', '16px 16px 0 16px']
    }
  }, [_c('c-box', {
    attrs: {
      "padding": ['32px 20px', '32px 83px'],
      "display": "flex",
      "flex-direction": ['column', 'row'],
      "gap": ['8px', '70px'],
      "justify-content": "center",
      "align-items": "center",
      "overflow": "hidden",
      "background-image": "linear-gradient(#CCE8E6 50%, transparent )"
    }
  }, [_c('c-box', {
    attrs: {
      "position": "relative",
      "width": "100%",
      "max-width": "350px",
      "display": ['none', 'block']
    }
  }, [(_vm$header$data = _vm.header.data) !== null && _vm$header$data !== void 0 && _vm$header$data.image ? _c('c-image', {
    attrs: {
      "src": (_vm$header$data2 = _vm.header.data) === null || _vm$header$data2 === void 0 ? void 0 : _vm$header$data2.image,
      "width": "100%",
      "height": "100%"
    }
  }) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "position": "relative"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px-2",
      "size-desktop": "36px-2",
      "text-align": "center",
      "color": "#005A64"
    }
  }, [_vm._v(" " + _vm._s(((_vm$header$data3 = _vm.header.data) === null || _vm$header$data3 === void 0 ? void 0 : _vm$header$data3.header) || '-') + " ")]), _c('BaseDivider', {
    attrs: {
      "border-color": "#005A64"
    }
  }), _c('c-box', {
    attrs: {
      "margin-top": ['14px', '30px']
    }
  }, _vm._l(((_vm$header$data4 = _vm.header.data) === null || _vm$header$data4 === void 0 ? void 0 : _vm$header$data4.descriptionItems) || [], function (dataHeader, dataHeaderIdx) {
    return _c('c-box', {
      key: dataHeaderIdx,
      attrs: {
        "margin-top": dataHeaderIdx === 0 ? '0' : ['18px', '16px']
      }
    }, [_c('BaseProgressBar', {
      attrs: {
        "margin-top": "8px",
        "initial-value": "0",
        "value": dataHeader.value,
        "delay": dataHeaderIdx * 0.1,
        "text": dataHeader.item
      }
    })], 1);
  }), 1), _c('c-flex', {
    attrs: {
      "margin-top": "45px",
      "box-shadow": "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
      "background-color": "white",
      "border-radius": "12px",
      "padding": ['12px 10px', '10px 20px']
    }
  }, [_c('c-box', {
    attrs: {
      "display": ['block', 'none'],
      "width": "100%",
      "max-width": "120px",
      "position": "relative"
    }
  }, [(_vm$header$data5 = _vm.header.data) !== null && _vm$header$data5 !== void 0 && _vm$header$data5.image ? _c('c-image', {
    attrs: {
      "top": "-50%",
      "object-fit": "contain",
      "position": "absolute",
      "src": (_vm$header$data6 = _vm.header.data) === null || _vm$header$data6 === void 0 ? void 0 : _vm$header$data6.image,
      "height": "130%",
      "width": "100%"
    }
  }) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" " + _vm._s(((_vm$header$data7 = _vm.header.data) === null || _vm$header$data7 === void 0 ? void 0 : _vm$header$data7.subheader) || '-') + " ")]), _c('c-box', {
    attrs: {
      "margin-top": ['12px', '20px'],
      "margin-bottom": ['0', '20px'],
      "font-size": ['14px', '16px']
    },
    domProps: {
      "innerHTML": _vm._s((_vm$header$data8 = _vm.header.data) === null || _vm$header$data8 === void 0 ? void 0 : _vm$header$data8.description)
    }
  })], 1)], 1)], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "id": "tes",
      "padding": ['16px 0 0 0', '16px'],
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_vm._v(" asdsad ")]), _c('c-box', {
    attrs: {
      "padding": "0 16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "id": "tes",
      "padding": ['12px 30px', '20px'],
      "flex-direction": ['column', 'row'],
      "align-items": "center",
      "box-shadow": "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
      "border-radius": "10px",
      "border-top-left-radius": "70px",
      "border-bottom-right-radius": "70px",
      "gap": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "flex-direction": "column",
      "gap": "16px",
      "width": ['100%', '525px']
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px-2",
      "size-desktop": "20px-2",
      "text-align": "center",
      "color": "#005A64"
    }
  }, [_vm._v(" " + _vm._s((_vm$layananUtama = _vm.layananUtama) === null || _vm$layananUtama === void 0 ? void 0 : (_vm$layananUtama$data = _vm$layananUtama.data) === null || _vm$layananUtama$data === void 0 ? void 0 : _vm$layananUtama$data.description) + " ")]), (_vm$layananUtama2 = _vm.layananUtama) !== null && _vm$layananUtama2 !== void 0 && (_vm$layananUtama2$dat = _vm$layananUtama2.data) !== null && _vm$layananUtama2$dat !== void 0 && _vm$layananUtama2$dat.image ? _c('c-image', {
    attrs: {
      "src": (_vm$layananUtama3 = _vm.layananUtama) === null || _vm$layananUtama3 === void 0 ? void 0 : (_vm$layananUtama3$dat = _vm$layananUtama3.data) === null || _vm$layananUtama3$dat === void 0 ? void 0 : _vm$layananUtama3$dat.image,
      "width": ['150px', '65%']
    }
  }) : _vm._e()], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "align-items": "center",
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "margin-top": ['0', '16px'],
      "white-space": "nowrap",
      "flex-wrap": "wrap",
      "gap": ['6px', '16px'],
      "justify-content": "center",
      "flex-direction": ['column', 'row'],
      "width": "100%"
    }
  }, _vm._l((_vm$layananUtama4 = _vm.layananUtama) === null || _vm$layananUtama4 === void 0 ? void 0 : (_vm$layananUtama4$dat = _vm$layananUtama4.data) === null || _vm$layananUtama4$dat === void 0 ? void 0 : _vm$layananUtama4$dat.descriptionItems, function (item) {
    return _c('c-flex', {
      key: item,
      attrs: {
        "align-items": "center",
        "justify-content": "space-between",
        "gap": "8px",
        "border-radius": "1000px",
        "padding": "10px 16px",
        "background-color": "white",
        "box-shadow": "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
        "font-size": ['12px', '16px']
      }
    }, [_vm._v(" " + _vm._s(item) + " "), _c('c-box', {
      attrs: {
        "width": ['18px', '20px'],
        "height": ['18px', '20px']
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-circle-check.svg'),
        "height": "100%",
        "width": "100%",
        "fill": "#008C81"
      }
    })], 1)], 1);
  }), 1), _c('BaseButton', {
    attrs: {
      "margin-top": ['12px', '30px'],
      "border-radius": "1000px"
    },
    on: {
      "click": function click($event) {
        var _vm$layananUtama5, _vm$layananUtama5$dat, _vm$layananUtama5$dat2;

        return _vm.$router.push(((_vm$layananUtama5 = _vm.layananUtama) === null || _vm$layananUtama5 === void 0 ? void 0 : (_vm$layananUtama5$dat = _vm$layananUtama5.data) === null || _vm$layananUtama5$dat === void 0 ? void 0 : (_vm$layananUtama5$dat2 = _vm$layananUtama5$dat.button) === null || _vm$layananUtama5$dat2 === void 0 ? void 0 : _vm$layananUtama5$dat2.url) || '#');
      }
    }
  }, [_vm._v(" " + _vm._s(((_vm$layananUtama6 = _vm.layananUtama) === null || _vm$layananUtama6 === void 0 ? void 0 : (_vm$layananUtama6$dat = _vm$layananUtama6.data) === null || _vm$layananUtama6$dat === void 0 ? void 0 : (_vm$layananUtama6$dat2 = _vm$layananUtama6$dat.button) === null || _vm$layananUtama6$dat2 === void 0 ? void 0 : _vm$layananUtama6$dat2.text) || '-') + " ")])], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "padding": ['16px 16px 0 16px']
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "display": "flex",
      "flex-direction": "column",
      "justify-content": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "28px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Apa Kata Mereka? ")]), _c('c-box', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll.x",
      value: !_vm.$isMobile(),
      expression: "!$isMobile()",
      modifiers: {
        "x": true
      }
    }],
    attrs: {
      "width": "100%",
      "overflow": "hidden",
      "display": "flex",
      "padding-bottom": "46px"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "display": "grid",
      "grid-gap": ['45px', '16px'],
      "grid-template-columns": ['repeat(1, 1fr)', 'repeat(5, 332px)'],
      "margin": "auto"
    }
  }, _vm._l(_vm.listTestimoni, function (item) {
    return _c('c-box', {
      key: item.id,
      attrs: {
        "position": "relative",
        "background-color": "#008C81",
        "border-radius": "12px",
        "padding": "16px",
        "display": "flex",
        "flex-direction": "column",
        "gap": "8px",
        "padding-bottom": "52px"
      }
    }, [_c('c-box', {
      attrs: {
        "display": "flex",
        "gap": "16px"
      }
    }, [_c('c-box', {
      attrs: {
        "min-width": "60px",
        "width": "60px",
        "height": "60px"
      }
    }, [item.image ? _c('c-image', {
      attrs: {
        "src": item.image,
        "height": "100%",
        "width": "100%",
        "border-radius": "100%",
        "object-fit": "cover",
        "object-position": "50% 20%"
      }
    }) : _vm._e()], 1), _c('c-box', {
      attrs: {
        "display": "flex",
        "flex-direction": "column",
        "justify-content": "center",
        "width": "100%"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "14px-2",
        "size-desktop": "16px",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.age) + " Tahun) ")]), _c('BaseText', {
      attrs: {
        "size-mobile": "12px",
        "size-desktop": "14px-2",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(item.location) + " ")])], 1)], 1), _c('c-box', {
      attrs: {
        "height": "100%"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "12px",
        "size-desktop": "14px-2",
        "margin-bottom": "auto",
        "text-align": "justify",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")])], 1), _c('c-box', {
      attrs: {
        "width": ['190px', '222px'],
        "min-height": ['64px', '67px'],
        "background-color": "white",
        "position": "absolute",
        "bottom": "0",
        "left": "50%",
        "border": "1px solid #008C81",
        "border-radius": "12px",
        "transform": "translate(-50%, 50%)",
        "padding": "5px",
        "display": "flex",
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        "gap": "5px"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "10px",
        "size-desktop": "12px"
      }
    }, [_vm._v(" Program yang digunakan: " + _vm._s(item.product.name) + " ")]), _c('BaseButton', {
      attrs: {
        "border-radius": "1000px",
        "right-svg-icon": require('@/assets/icons/icon-arrow-right.svg'),
        "right-svg-icon-color": "white",
        "height": "34px"
      }
    }, [_vm._v(" Lihat layanan ")])], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "padding": ['16px 0 0 0', '16px 16px 0 16px']
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "display": "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" Masalah Lainnya ")]), _c('c-box', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll.x",
      value: !_vm.$isMobile(),
      expression: "!$isMobile()",
      modifiers: {
        "x": true
      }
    }],
    staticClass: "no-scrollbar",
    attrs: {
      "width": "100%",
      "overflow-y": "hidden",
      "overflow-x": "scroll",
      "display": "flex",
      "padding-top": "8px",
      "padding-bottom": "16px"
    },
    on: {
      "!click": function click($event) {
        return function (e) {
          return _vm.dragScrollClickFix.onClickCapture(e);
        }.apply(null, arguments);
      },
      "dragscrollstart": function dragscrollstart($event) {
        return _vm.dragScrollClickFix.onDragScrollStart();
      },
      "dragscrollend": function dragscrollend($event) {
        return _vm.dragScrollClickFix.onDragScrollEnd();
      }
    }
  }, [_c('c-box', {
    attrs: {
      "display": "grid",
      "grid-gap": ['20px', '16px'],
      "grid-template-columns": ['repeat(5, 180px)', 'repeat(5, 230px)'],
      "margin": "auto"
    }
  }, _vm._l(_vm.listOtherProblems.data, function (item) {
    return _c('c-box', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '&:first-child': {
            marginLeft: '16px'
          },
          '&:last-child': {
            marginRight: '16px'
          },
          ':hover': {
            backgroundColor: '#f3f3f3'
          },
          ':active': {
            backgroundColor: '#eaeaea'
          }
        },
        expression: "{\n                '&:first-child': {\n                  marginLeft: '16px'\n                },\n                '&:last-child': {\n                  marginRight: '16px'\n                },\n                ':hover': {\n                  backgroundColor: '#f3f3f3'\n                },\n                ':active': {\n                  backgroundColor: '#eaeaea'\n                }\n              }"
      }],
      key: item.id,
      attrs: {
        "as": "button",
        "background-color": "white",
        "box-shadow": ['2px 2px 10px rgba(0, 0, 0, 0.15)'],
        "border-radius": "16px",
        "padding": "16px",
        "display": "flex",
        "flex-direction": "column",
        "gap": "8px",
        "justify-content": "center",
        "align-items": "center"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "18px",
        "size-desktop": "28px",
        "color": ['#008C81'],
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('c-image', {
      attrs: {
        "src": item.image,
        "min-height": "80px",
        "height": "100%",
        "max-height": ['80px', '100px']
      }
    }), _c('BaseText', {
      attrs: {
        "size-mobile": "14px-2",
        "size-desktop": "14px-2",
        "margin-bottom": "auto",
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")])], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }