<template>
  <c-box
    :background-color="['#F2F2F2', '#FFF']"
    padding-bottom="16px"
    width="100%"
  >
    <c-box
      position="relative"
      mx="auto"
      :background-color="['#F2F2F2', '#FFF']"
      border="1px solid #f2f2f2"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :min-height="['calc(100vh - 62px)', '74vh']"
      width="100%"
      max-width="1270px"
    >
      <!-- DIETELA TERBUKTI -->
      <c-box :padding="['0 0 0 0', '16px 16px 0 16px']">
        <c-box
          :padding="['32px 20px', '32px 83px']"
          display="flex"
          :flex-direction="['column', 'row']"
          :gap="['8px', '70px']"
          justify-content="center"
          align-items="center"
          overflow="hidden"
          background-image="linear-gradient(#CCE8E6 50%, transparent )"
        >
          <c-box
            position="relative"
            width="100%"
            max-width="350px"
            :display="['none', 'block']"
          >
            <c-image
              v-if="header.data?.image"
              :src="header.data?.image"
              width="100%"
              height="100%"
            />
          </c-box>
          <c-box
            width="100%"
            position="relative"
          >
            <BaseText
              size-mobile="18px-2"
              size-desktop="36px-2"
              text-align="center"
              color="#005A64"
            >
              {{ header.data?.header || '-' }}
            </BaseText>

            <BaseDivider border-color="#005A64" />

            <c-box
              :margin-top="['14px', '30px']"
            >
              <c-box
                v-for="(dataHeader, dataHeaderIdx) in header.data?.descriptionItems || []"
                :key="dataHeaderIdx"
                :margin-top="dataHeaderIdx === 0 ? '0' : ['18px', '16px']"
              >
                <BaseProgressBar
                  margin-top="8px"
                  initial-value="0"
                  :value="dataHeader.value"
                  :delay="dataHeaderIdx * 0.1"
                  :text="dataHeader.item"
                />
              </c-box>
            </c-box>
            <c-flex
              margin-top="45px"
              box-shadow="0px 4px 20px 0px rgba(0, 0, 0, 0.15)"
              background-color="white"
              border-radius="12px"
              :padding="['12px 10px', '10px 20px']"
            >
              <c-box
                :display="['block', 'none']"
                width="100%"
                max-width="120px"
                position="relative"
              >
                <c-image
                  v-if="header.data?.image"
                  top="-50%"
                  object-fit="contain"
                  position="absolute"
                  :src="header.data?.image"
                  height="130%"
                  width="100%"
                />
              </c-box>
              <c-box
                width="100%"
              >
                <BaseText
                  size-mobile="18px"
                  size-desktop="20px"
                  color="#008C81"
                >
                  {{ header.data?.subheader || '-' }}
                </BaseText>
                <c-box
                  :margin-top="['12px', '20px']"
                  :margin-bottom="['0', '20px']"
                  :font-size="['14px', '16px']"
                  v-html="header.data?.description"
                />
              </c-box>
            </c-flex>
          </c-box>
        </c-box>
      </c-box>

      <!-- Pilih Program Sesuai Kebutuhanmu -->
      <c-box
        id="tes"
        :padding="['16px 0 0 0', '16px']"
        display="flex"
        flex-direction="column"
        align-items="center"
      >
        asdsad
      </c-box>

      <!-- Layanan Utama -->
      <c-box padding="0 16px">
        <c-flex
          id="tes"
          :padding="['12px 30px', '20px']"
          :flex-direction="['column', 'row']"
          align-items="center"
          box-shadow="0px 4px 20px 0px rgba(0, 0, 0, 0.15)"
          border-radius="10px"
          border-top-left-radius="70px"
          border-bottom-right-radius="70px"
          gap="16px"
        >
          <c-flex
            align-items="center"
            flex-direction="column"
            gap="16px"
            :width="['100%', '525px']"
          >
            <BaseText
              size-mobile="16px-2"
              size-desktop="20px-2"
              text-align="center"
              color="#005A64"
            >
              {{ layananUtama?.data?.description }}
            </BaseText>
            <c-image
              v-if="layananUtama?.data?.image"
              :src="layananUtama?.data?.image"
              :width="['150px', '65%']"
            />
          </c-flex>
          <c-flex
            flex-direction="column"
            align-items="center"
            width="100%"
          >
            <c-flex
              :margin-top="['0', '16px']"
              white-space="nowrap"
              flex-wrap="wrap"
              :gap="['6px', '16px']"
              justify-content="center"
              :flex-direction="['column', 'row']"
              width="100%"
            >
              <c-flex
                v-for="item in layananUtama?.data?.descriptionItems"
                :key="item"
                align-items="center"
                justify-content="space-between"
                gap="8px"
                border-radius="1000px"
                padding="10px 16px"
                background-color="white"
                box-shadow="0px 4px 20px 0px rgba(0, 0, 0, 0.15)"
                :font-size="['12px', '16px']"
              >
                {{ item }}
                <c-box
                  :width="['18px', '20px']"
                  :height="['18px', '20px']"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-circle-check.svg')"
                    height="100%"
                    width="100%"
                    fill="#008C81"
                  />
                </c-box>
              </c-flex>
            </c-flex>
            <BaseButton
              :margin-top="['12px', '30px']"
              border-radius="1000px"
              @click="$router.push(layananUtama?.data?.button?.url || '#')"
            >
              {{ layananUtama?.data?.button?.text || '-' }}
            </BaseButton>
          </c-flex>
        </c-flex>
      </c-box>

      <!--    asdasd  -->
      <c-box
        :padding="['16px 16px 0 16px']"
      >
        <c-box
          width="100%"
          display="flex"
          flex-direction="column"
          justify-content="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="28px"
            margin-bottom="8px"
          >
            Apa Kata Mereka?
          </BaseText>

          <c-box
            v-dragscroll.x="!$isMobile()"
            width="100%"
            overflow="hidden"
            display="flex"
            padding-bottom="46px"
          >
            <c-box
              width="100%"
              display="grid"
              :grid-gap="['45px', '16px']"
              :grid-template-columns="['repeat(1, 1fr)', 'repeat(5, 332px)']"
              margin="auto"
            >
              <c-box
                v-for="(item) in listTestimoni"
                :key="item.id"
                position="relative"
                background-color="#008C81"
                border-radius="12px"
                padding="16px"
                display="flex"
                flex-direction="column"
                gap="8px"
                padding-bottom="52px"
              >
                <c-box
                  display="flex"
                  gap="16px"
                >
                  <c-box
                    min-width="60px"
                    width="60px"
                    height="60px"
                  >
                    <c-image
                      v-if="item.image"
                      :src="item.image"
                      height="100%"
                      width="100%"
                      border-radius="100%"
                      object-fit="cover"
                      object-position="50% 20%"
                    />
                  </c-box>
                  <c-box
                    display="flex"
                    flex-direction="column"
                    justify-content="center"
                    width="100%"
                  >
                    <BaseText
                      size-mobile="14px-2"
                      size-desktop="16px"
                      color="white"
                    >
                      {{ item.name }} ({{ item.age }} Tahun)
                    </BaseText>
                    <BaseText
                      size-mobile="12px"
                      size-desktop="14px-2"
                      color="white"
                    >
                      {{ item.location }}
                    </BaseText>
                  </c-box>
                </c-box>
                <c-box
                  height="100%"
                >
                  <BaseText
                    size-mobile="12px"
                    size-desktop="14px-2"
                    margin-bottom="auto"
                    text-align="justify"
                    color="white"
                  >
                    {{ item.description }}
                  </BaseText>
                </c-box>
                <c-box
                  :width="['190px','222px']"
                  :min-height="['64px', '67px']"
                  background-color="white"
                  position="absolute"
                  bottom="0"
                  left="50%"
                  border="1px solid #008C81"
                  border-radius="12px"
                  transform="translate(-50%, 50%)"
                  padding="5px"
                  display="flex"
                  flex-direction="column"
                  justify-content="center"
                  align-items="center"
                  gap="5px"
                >
                  <BaseText
                    size-mobile="10px"
                    size-desktop="12px"
                  >
                    Program yang digunakan: {{ item.product.name }}
                  </BaseText>
                  <BaseButton
                    border-radius="1000px"
                    :right-svg-icon="require('@/assets/icons/icon-arrow-right.svg')"
                    right-svg-icon-color="white"
                    height="34px"
                  >
                    <!--                    @click="handleClickTestimoniItem(item)"-->
                    Lihat layanan
                  </BaseButton>
                </c-box>
              </c-box>
            </c-box>
          </c-box>
        </c-box>
      </c-box>

      <!--    masalah lainnya  -->
      <c-box
        :padding="['16px 0 0 0', '16px 16px 0 16px']"
      >
        <c-box
          width="100%"
          display="flex"
          flex-direction="column"
          justify-content="center"
          align-items="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="28px"
          >
            Masalah Lainnya
          </BaseText>

          <c-box
            v-dragscroll.x="!$isMobile()"
            width="100%"
            overflow-y="hidden"
            overflow-x="scroll"
            display="flex"
            padding-top="8px"
            padding-bottom="16px"
            class="no-scrollbar"
            @click.capture="e => dragScrollClickFix.onClickCapture(e)"
            @dragscrollstart="dragScrollClickFix.onDragScrollStart()"
            @dragscrollend="dragScrollClickFix.onDragScrollEnd()"
          >
            <c-box
              display="grid"
              :grid-gap="['20px', '16px']"
              :grid-template-columns="['repeat(5, 180px)', 'repeat(5, 230px)']"
              margin="auto"
            >
              <c-box
                v-for="(item) in listOtherProblems.data"
                :key="item.id"
                v-chakra="{
                  '&:first-child': {
                    marginLeft: '16px'
                  },
                  '&:last-child': {
                    marginRight: '16px'
                  },
                  ':hover': {
                    backgroundColor: '#f3f3f3'
                  },
                  ':active': {
                    backgroundColor: '#eaeaea'
                  }
                }"
                as="button"
                background-color="white"
                :box-shadow="['2px 2px 10px rgba(0, 0, 0, 0.15)']"
                border-radius="16px"
                padding="16px"
                display="flex"
                flex-direction="column"
                gap="8px"
                justify-content="center"
                align-items="center"
              >
                <!--                @click="handleClickOtherProblem(item)"-->
                <BaseText
                  size-mobile="18px"
                  size-desktop="28px"
                  :color="['#008C81']"
                  text-align="center"
                >
                  {{ item.title }}
                </BaseText>
                <c-image
                  :src="item.image"
                  min-height="80px"
                  height="100%"
                  :max-height="['80px', '100px']"
                />
                <BaseText
                  size-mobile="14px-2"
                  size-desktop="14px-2"
                  margin-bottom="auto"
                  text-align="center"
                >
                  {{ item.description }}
                </BaseText>
              </c-box>
            </c-box>
          </c-box>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BaseProgressBar from '@/components/elements/base-progress-bar-2.vue'
import { mapActions, mapGetters } from 'vuex'
import { formatCurrency } from '@/utils/format-currency'
import { reqProgramKonsultasi } from '@/requests/dietela-api/general/program-konsultasi'
import { CBox, CImage, CFlex } from '@chakra-ui/vue'
import { dragscroll } from 'vue-dragscroll'
import BaseDivider from '@/components/elements/base-divider.vue'

class DragScrollClickFix {
  constructor() {
    this.DRAG_DELAY = 100 // This is the minimal delay to consider a click to be a drag, mostly usefull for touch devices
    this.timer = null
    this.dragging = false
  }

  onDragScrollStart() {
    this.timer = setTimeout(() => this.onTimer(), this.DRAG_DELAY)
  }

  onTimer() {
    this.timer = null
    this.dragging = true
  }

  onDragScrollEnd() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    setTimeout(() => this.dragging = false)
  }

  onClickCapture(e) {
    if (this.dragging) {
      this.dragging = false
      e.preventDefault()
      e.stopPropagation()
    }
  }
}

export default {
  name: 'ExtendProgram',
  components: {
    BaseDivider,
    BaseProgressBar,
    BaseButton,
    BaseText,
    CBox,
    CImage,
    CFlex,
  },
  directives: {
    dragscroll,
  },
  data() {
    return {
      listTestimoni: [],
      listOtherProblems: [],
      header: {},
      layananUtama: {},
      dragScrollClickFix: new DragScrollClickFix(),
    }
  },
  computed: {
    ...mapGetters({
      userType: 'userType/userType',
    }),
  },
  mounted() {
    this.init()
    this.triggerUpdateUserType()
  },

  methods: {
    formatCurrency,
    ...mapActions({
      getTestimoni: 'general/getTestimoni',
      triggerUpdateUserType: 'userType/triggerUpdateUserType',
    }),

    async init() {
      const header_ = await reqProgramKonsultasi.header(this.$store.getters.axios)
      this.header = header_?.data

      const layananUtama = await reqProgramKonsultasi.layananUtama(this.$store.getters.axios)
      this.layananUtama = layananUtama?.data

      const otherProblems = await reqProgramKonsultasi.otherProblems(this.$store.getters.axios)
      this.listOtherProblems = otherProblems?.data || []

      const testimoni = await this.getTestimoni()
      this.listTestimoni = testimoni.data || []
    },
  },
}
</script>
